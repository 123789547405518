<template>
  <div>
    <!-- BEGIN breadcrumb -->
    <ol class="breadcrumb float-xl-end">
      <li class="breadcrumb-item"><a href="/app/dashboard">Home</a></li>

      <li class="breadcrumb-item">
        <a href="/app/ordem-servico">Ordens de serviços</a>
      </li>
      <li class="breadcrumb-item">Impressão</li>
      <!-- <button @click="goBack" class="btn btn-info">Voltar</button> -->
    </ol>
    <!-- END breadcrumb -->
    <!-- BEGIN page-header -->
    <h1 class="page-header">Impressão</h1>
    <!-- END page-header -->
    <panel :noButton="true">
      <div
        slot="header"
        class="w-100 d-flex align-items-center justify-content-between"
      >
        <span>Listagem dos dados da impressão </span>
      </div>
      <vue-good-table
        :columns="columns"
        :rows="rows"
        :lineNumbers="false"
        :styleClass="'vgt-table striped'"
        :search-options="{ enabled: true, placeholder: 'Pesquisar' }"
        :pagination-options="pageOptions"
      >
        <template slot="table-row" slot-scope="props">
          <span v-if="props.column.field === 'os'">
            {{ props.row.ordem_servico.toString().padStart(6, '0') }}
          </span>
          <span v-if="props.column.field === 'nome_exibicao'">
            {{ props.row.nome_exibicao }}
          </span>
          <span v-if="props.column.field === 'qtd_objetos'">
            {{ props.row.qtd_objetos }}
          </span>
          <span v-if="props.column.field === 'caixa'">
            {{ props.row.caixa }}
          </span>
          <span v-if="props.column.field === 'data_limite_entrega'">
            {{ props.row.data_limite_entrega }}
          </span>
          <span v-if="props.column.field === 'cliente'">
            {{ props.row.cliente }}
          </span>
          <span v-if="props.column.field === 'produto'">
            {{ props.row.produto }}
          </span>

          <span v-if="props.column.field === 'acao'">
            <button
              v-if="checkFuncionalidade(props.row.botao === 'IMPRIMIR' ? 3 : 5)"
              style="width: 96px"
              class="btn me-2"
              :class="
                props.row.botao === 'IMPRIMIR'
                  ? 'btn-success'
                  : props.row.botao === 'REIMPRIMIR'
                  ? 'btn-warning'
                  : 'btn-info'
              "
              @click="detalhesImpressao(props.row)"
            >
              {{ props.row.botao }}
            </button>
          </span>
        </template>
      </vue-good-table>
    </panel>
    <modal-impressao v-if="ordem" :loadItens="loadItens" :ordem="ordem" />
    <modal-reimpressao v-if="ordem" :loadItens="loadItens" :ordem="ordem" />
    <modal-refaz v-if="ordem" :loadItens="loadItens" :ordem="ordem" />
  </div>
</template>

<script>
import axios from 'axios'
import ModalImpressao from './components/ModalImpressao.vue'
import ModalReimpressao from './components/ModalReimpressao.vue'
import ModalRefaz from './components/ModalRefaz.vue'
import CheckFunc from '../../../mixins/CheckFunc'
export default {
  components: {
    ModalImpressao,
    ModalReimpressao,
    ModalRefaz
  },
  mixins: [CheckFunc],
  data() {
    return {
      load: false,
      ordem: undefined,
      columns: [
        {
          label: 'Os',
          field: 'os',
          sortable: false
        },
        {
          label: 'Nome',
          field: 'nome_exibicao',
          sortable: false
        },
        {
          label: 'Caixa',
          field: 'caixa',
          sortable: false
        },
        {
          label: 'Qtd. Objetos',
          field: 'qtd_objetos',
          sortable: false
        },
        {
          label: 'Data Limite',
          field: 'data_limite_entrega',
          sortable: false
        },
        {
          label: 'Ações',
          field: 'acao',
          tdClass: '',
          sortable: false
        }
      ],
      rows: []
    }
  },
  methods: {
    goBack() {
      window.history.back()
    },
    detalhesImpressao(ordem) {
      this.ordem = ordem
      let modalId = 'modal-impressao'
      setTimeout(() => {
        if (ordem.botao === 'REIMPRIMIR') modalId = 'modal-reimpressao'
        else if (ordem.botao === 'REFAZ') modalId = 'modal-refaz'
        this.$bvModal.show(modalId)
      }, 200)
    },
    async loadItens() {
      this.load = true
      await axios
        .get(
          `producao/listar-arquivos?page=1&per_page=10000&ordem_servico=` +
            this.$route.params.id
        )
        .then(res => {
          return res.data.dados
        })
        .then(res => {
          this.rows = res.data
          this.load = false
        })
    }
  },
  computed: {
    pageOptions() {
      return {
        enabled: true,
        mode: 'records',
        perPage: 10,
        position: 'bottom',
        perPageDropdown: [10, 50, 100],
        dropdownAllowAll: false,
        setCurrentPage: 1,
        jumpFirstOrLast: true,
        firstLabel: 'Primeira Página',
        lastLabel: 'Última Página',
        nextLabel: 'Próxima',
        prevLabel: 'Anterior',
        rowsPerPageLabel: 'Linhas por página',
        ofLabel: 'de',
        pageLabel: 'página', // for 'pages' mode
        allLabel: 'Todos',
        infoFn: params => `Página atual ${params.firstRecordOnPage}`
      }
    }
  },
  mounted() {
    this.loadItens()
  }
}
</script>
